import React from "react"
import "normalize.css"
import Footer from "./Footer"
import Nav from "./Nav"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <main style={{ marginTop: "75px", marginBottom: "75px" }}>
        {" "}
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
