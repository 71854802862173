import { createGlobalStyle } from "styled-components"

import boldFont from "../assets/fonts/FreeSansBold.otf"
import lightFont from "../assets/fonts/Nimbus-Sans-D-OT-Light_32752.ttf"

const Typography = createGlobalStyle`
  @font-face {
    font-family: light;
    src: url(${lightFont});
  }

  @font-face {
    font-family: bold;
    src: url(${boldFont});
  }

  html {
    font-family: light, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p{
    letter-spacing: 0.2em;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    letter-spacing: 0.4em;
    
  }
  a {
    color: var(--black);
    text-decoration: none;
    letter-spacing: 0.2em;

   
  }

  ul{
    list-style: none;
    
  }
 
  .center {
    text-align: center;
  }
  
`

export default Typography
