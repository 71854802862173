import React from "react"
import { Link } from "gatsby"
import { StyledMobileMenu } from "./MobileMenu.styled"
import { Fade } from "react-awesome-reveal"

const MobileMenu = ({ open, setOpen }) => {
  const closeHandle = () => setOpen(!open)
  return (
    <StyledMobileMenu open={open}>
      <Fade duration={500} cascade>
        <Link onClick={closeHandle} to="/cafe">
          Cafe
        </Link>
        <Link onClick={closeHandle} to="/cowork">
          COWORK
        </Link>
        <Link onClick={closeHandle} to="/rooms">
          Rooms
        </Link>
        <Link onClick={closeHandle} to="/studio">
          Studio
        </Link>
        <Link onClick={closeHandle} to="/shop">
          Shop
        </Link>
        <Link onClick={closeHandle} to="/contact">
          Contact
        </Link>
      </Fade>
    </StyledMobileMenu>
  )
}
export default MobileMenu
