import React from "react"
import styled from "styled-components"

const FooterStyles = styled.footer`
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 75px;
  width: 100%;
  background: var(--black);
  color: var(--white);

  .footer__subText {
    display: flex;
    width: 147px;
    margin-left: -3px;
    justify-content: space-between;
    font-size: 8px;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  .footer__bookNow {
    h3 {
      font-size: 17px;
      text-align: center;
      color: var(--white);

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
function Footer() {
  return (
    <FooterStyles>
      <div className="footer__subText">
        <p>work</p>
        <p>-</p>
        <p>surf</p>
        <p>-</p>
        <p>skate</p>
        <p>-</p>
        <p>create</p>
      </div>
      <div className="footer__bookNow">
        <a href="https://staahmax.staah.net/be/index_be?propertyId=MTMwMA==">
          <h3>BOOK NOW</h3>
        </a>
      </div>
    </FooterStyles>
  )
}
export default Footer
