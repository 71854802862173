// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cafe-js": () => import("./../../../src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cowork-js": () => import("./../../../src/pages/cowork.js" /* webpackChunkName: "component---src-pages-cowork-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

