import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../assets/images/verse_logo.png"
import Burger from "./Burger/Burger"
import MobileMenu from "./MobileMenu/MobileMenu"

const NavStyles = styled.nav`
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 75px;
  align-items: center;
  background: var(--black);
  padding: 0 20px;

  @media (max-width: 900px) {
    justify-content: space-between;
  }

  a {
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: 0.45em;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: 65px;
  }

  .nav__links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
      display: none;
    }

    ul {
      display: flex;
      margin-left: -85px;
      text-align: center;
    }

    li {
      margin-left: 4vw;
    }
  }
`

const Nav = () => {
  const [open, setOpen] = useState(false)

  return (
    <NavStyles>
      <div>
        <Link to="/">
          <img src={logo} alt="Verse Collective Logo" />
        </Link>
      </div>
      <div className="nav__links">
        <ul>
          <li className="hvr-pop">
            <Link to="/cafe/">Cafe</Link>
          </li>
          <li className="hvr-pop">
            <Link to="/cowork/">Cowork</Link>
          </li>
          <li className="hvr-pop">
            <Link to="/rooms/">Rooms</Link>
          </li>
          <li className="hvr-pop">
            <Link to="/studio/">Studio</Link>
          </li>
          <li className="hvr-pop">
            <Link to="/shop/">Shop</Link>
          </li>
          <li className="hvr-pop">
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
      </div>
      <div>
        <Burger open={open} setOpen={setOpen} />
        <MobileMenu open={open} setOpen={setOpen} />
      </div>
    </NavStyles>
  )
}

export default Nav
