import styled from "styled-components"

export const StyledMobileMenu = styled.div`
  display: none;
  flex-direction: column;
  background: var(--black);
  height: calc(100vh - 150px);
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 75px;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};

  div {
    margin: 20px;
  }

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
  }

  a {
    color: var(--white);
    transition: color 0.3s linear;

    @media (max-width: 900px) {
      font-size: 1.8rem;
      text-align: center;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`
