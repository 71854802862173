import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  
  :root {
    
    --black: #2E2D2C; 
    --white: #fff;
    --window: 
    
    
  }

  body{
    font-size:.9em;
    
  }


/* Scrollbar Styles */

body::-webkit-scrollbar{
  width:12px
}

html {
    scrollbar-width: thin;
    scrollbar-color: var(--white) var(--black);
    
  }
  body::-webkit-scrollbar-track {
    background: var(--white); 
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--black) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

/* Utility Styles */

  @-webkit-keyframes hvr-pop {
      50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    @keyframes hvr-pop {
      50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    .hvr-pop {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    }
    .hvr-pop:hover,
    .hvr-pop:focus,
    .hvr-pop:active {
      -webkit-animation-name: hvr-pop;
      animation-name: hvr-pop;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
`

export default GlobalStyles
